export const commonMixins = {
  data () {
    return {
      deviceOptions: [
      {
        label: 'Portatile 13"',
        value: 'Portatile 13"',
        img: '/img/configuration/notebook13.svg'
      },
      {
        label: 'Portatile 14"',
        value: 'Portatile 14"',
        img: '/img/configuration/notebook14.svg'
      },
      {
        label: 'Portatile 15"',
        value: 'Portatile 15"',
        img: '/img/configuration/notebook15.svg'
      },
      {
        label: 'Workstation Fissa',
        value: 'Workstation Fissa',
        img: '/img/configuration/workstationFissa.svg'
      },
      {
        label: 'Workstation Portatile',
        value: 'Workstation Portatile',
        img: '/img/configuration/workstationPortatile.svg'
      },
      {
        label: 'Desktop',
        value: 'Desktop',
        img: '/img/configuration/desktop.svg'
      },
      {
        label: 'Iphone',
        value: 'Iphone',
        img: '/img/configuration/iphone.svg'
      },
      {
        label: 'Ipad',
        value: 'Ipad',
        img: '/img/configuration/ipad.svg'
      }
    ],
      vendorOptions: [
        {
          value: 'apple',
          img: '/img/vendor/apple.svg'
        },
        {
          value: 'dell',
          img: '/img/vendor/dell.png'
        },
        {
          value: 'hp',
          img: '/img/vendor/hp.png'
        },
        {
          value: 'lenovo',
          img: '/img/vendor/lenovo.png'
        },
        {
          value: 'microsoft',
          img: '/img/vendor/microsoft.png'
        }
      ],
      deviceConfigMapping: {
        'default': {
          showBand: true,
          vendorOptions: [
            {
              value: 'apple',
              img: '/img/vendor/apple.svg'
            },
            {
              value: 'dell',
              img: '/img/vendor/dell.png'
            },
            {
              value: 'hp',
              img: '/img/vendor/hp.png'
            },
            {
              value: 'lenovo',
              img: '/img/vendor/lenovo.png'
            },
            {
              value: 'microsoft',
              img: '/img/vendor/microsoft.png'
            },
          ],
          accessoriesOptions: [
            { checked: false, label: 'Docking Station USB-C', tooltip: ''},
            { checked: false, label: 'Mouse + Tastiera', tooltip: ''},
            { checked: false, label: 'Monitor Esterno', tooltip: ''}
          ],
          activableServicesOptions: [
            { checked: false, label: 'Noltech Start', tooltip: 'È il servizio base incluso nel canone che comprende: la configurazione personalizzata e la consegna dei dispositivi, la cybersecurity e l’accesso alla piattaforma MyElmec per la governance degli asset IT.'},
            { checked: false, label: 'IperGaranzia Elmec', tooltip: 'Estensione del supporto standard del produttore con la manutenzione in tutta Europa mediante device sostitutivi pre-configurati e in pronta consegna.'},
            { checked: false, label: 'Smart Rollout', tooltip: 'Aggiornamento della flotta aziendale senza interruzioni di business con il supporto on site dei tecnici Noltech.'},
            { checked: false, label: 'Blocco e Wipe Certificato', tooltip: 'Blocco dei device da remoto e cancellazione certificata dei dati dai dispositivi al termine del contratto.'},
            { checked: false, label: 'Licensing Office 365', tooltip: 'Gestione delle licenze Microsoft a partire dagli aspetti contrattuali fino al deployment tecnico sui device.'}
          ]
        },
        'Desktop': {
          showBand: false,
          accessoriesOptions: [
            { checked: false, label: 'Mouse + Tastiera', tooltip: ''},
            { checked: false, label: 'Monitor Esterno', tooltip: ''}
          ],
          activableServicesOptions: [
            { checked: false, label: 'Noltech Start', tooltip: 'È il servizio base incluso nel canone che comprende: la configurazione personalizzata e la consegna dei dispositivi, la cybersecurity e l’accesso alla piattaforma MyElmec per la governance degli asset IT.'},
            { checked: false, label: 'Smart Rollout', tooltip: 'Aggiornamento della flotta aziendale senza interruzioni di business con il supporto on site dei tecnici Noltech.'},
            { checked: false, label: 'Blocco e Wipe Certificato', tooltip: 'Blocco dei device da remoto e cancellazione certificata dei dati dai dispositivi al termine del contratto.'},
            { checked: false, label: 'Licensing Office 365', tooltip: 'Gestione delle licenze Microsoft a partire dagli aspetti contrattuali fino al deployment tecnico sui device.'}
          ]
        },
        'Workstation Fissa': {
          showBand: false
        },
        'Workstation Portatile': {
          showBand: false
        },
        'Iphone': {
          showBand: false,
          vendorOptions: [
            {
              value: 'apple',
              img: '/img/vendor/apple.svg'
            },
          ],
          accessoriesOptions: [
            { checked: false, label: 'Cover', tooltip: ''},
            { checked: false, label: 'Vetrino', tooltip: ''},
          ],
          activableServicesOptions: [
            { checked: false, label: 'Noltech Start', tooltip: 'È il servizio base incluso nel canone che comprende: la configurazione personalizzata e la consegna dei dispositivi, la cybersecurity e l’accesso alla piattaforma MyElmec per la governance degli asset IT.'},
            { checked: false, label: 'Smart Rollout', tooltip: 'Aggiornamento della flotta aziendale senza interruzioni di business con il supporto on site dei tecnici Noltech.'},
            { checked: false, label: 'Apple Care for Enterprise', tooltip: 'Servizio di riparazione e sostituzione degli hardware Apple disponibile worldwide.'},
            { checked: false, label: 'Servizio MDM', tooltip: 'Servizio pensato per gli utenti in mobilità: configura tutti i device in modo rapido, gestisci il provisioning di app e stabilisci policy in base al profilo utente.'}
          ]
        },
        'Ipad': {
          showBand: false,
          vendorOptions: [
            {
              value: 'apple',
              img: '/img/vendor/apple.svg'
            },
          ],
          accessoriesOptions: [
            { checked: false, label: 'Cover', tooltip: ''},
            { checked: false, label: 'Keyboard', tooltip: ''},
            { checked: false, label: 'Pencil', tooltip: ''},
          ],
          activableServicesOptions: [
            { checked: false, label: 'Noltech Start', tooltip: 'È il servizio base incluso nel canone che comprende: la configurazione personalizzata e la consegna dei dispositivi, la cybersecurity e l’accesso alla piattaforma MyElmec per la governance degli asset IT.'},
            { checked: false, label: 'Smart Rollout', tooltip: 'Aggiornamento della flotta aziendale senza interruzioni di business con il supporto on site dei tecnici Noltech.'},
            { checked: false, label: 'Apple Care for Enterprise', tooltip: 'Servizio di riparazione e sostituzione degli hardware Apple disponibile worldwide.'},
            { checked: false, label: 'Servizio MDM', tooltip: 'Servizio pensato per gli utenti in mobilità: configura tutti i device in modo rapido, gestisci il provisioning di app e stabilisci policy in base al profilo utente.'}
          ]
        },
      }
    }
  },
  methods: {
    richiediPreventivo: function () {
      this.$router.push({
        name: 'Configurator',
      })
    },
    goToHome: function () {
      if (this.$route.name === 'Home') {
        window.location.reload()
      } else {
        this.$router.push({
          name: 'Home'
        })
      }
    },
    goTopPage: function () {
      window.scrollTo(0,0)
    },
    getConfig: function (key, attribute) {
      return this.deviceConfigMapping[key] && attribute in this.deviceConfigMapping[key] ? this.deviceConfigMapping[key][attribute] : this.deviceConfigMapping['default'][attribute]
    }
  }
}
