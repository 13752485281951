import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store/store'
import VeeValidate from 'vee-validate'
import VTooltip from 'v-tooltip'
import 'v-tooltip/dist/v-tooltip.css'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'


Vue.use(VeeValidate)
Vue.use(VTooltip)
Vue.component('VueSlider', VueSlider)


Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
