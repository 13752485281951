import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/components/base/Home'),
  },
  {
    path: '/preventivo',
    name: 'Preventivo',
    component: () => import('@/components/Preventivo'),
  },
  {
    path: '/thank-you',
    name: 'ThankYou',
    component: () => import('@/components/ThankYou'),
  },
  {
    path: '/configuration',
    name: 'Configurator',
    props: true,
    component: () => import('@/components/Configurator')
  },
  {
    path: '/preventivo-noleggio',
    name: 'PreventivoNoleggio',
    component: () => import('@/components/PreventivoNoleggio')
  },
  {
      path: '/it-manager',
      name: 'ItManager',
      component: () => import('@/components/pages/ItManager')
  },
    {
        path: '/cfo',
        name: 'Cfo',
        component: () => import('@/components/pages/Cfo')
    },
    {
        path: '/hr-manager',
        name: 'HrManager',
        component: () => import('@/components/pages/HrManager')
    }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
