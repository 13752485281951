<template>
  <!--header section start-->
  <header class="header">
    <!--start navbar-->
    <nav class="navbar navbar-expand-lg fixed-top bg-transparent">
      <div class="container">
        <a class="navbar-brand link" @click="goToHome"><img src="/img/logoWhiteSenza.svg" width="180" alt="logo"
                                                       class="img-fluid"></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="ti-menu"></span>
        </button>

        <div class="collapse navbar-collapse main-menu" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto">

            <li class="nav-item">
              <a @click="goTo('#slider-steps')" class="nav-link page-scroll" href="#slider-steps">Il noleggio</a>
            </li>
            <li class="nav-item">
              <a @click="goTo('#iservizi')" class="nav-link page-scroll" href="#iservizi">I servizi</a>
            </li>
            <li class="nav-item">
              <a @click="goTo('#pricing')" class="nav-link page-scroll" href="#vantaggi">I vantaggi</a>
            </li>
            <li class="nav-item">
              <a @click="goTo('#chisiamo')" class="nav-link page-scroll" href="#chisiamo">Chi siamo</a>
            </li>

           <!-- <li class="nav-item">
              <a @click="goTo('#faq')" class="nav-link page-scroll" href="#faq">Faq</a>
            </li>-->


          </ul>
        </div>
      </div>
    </nav>
    <!--end navbar-->
  </header>
  <!--header section end-->
</template>

<script>
  import {commonMixins} from '../../mixins/common-mixins'

  export default {
    name: 'AppHeader',
    mixins: [commonMixins],
    methods: {
      goTo: function (section) {
        if (this.$route.name === 'Home') return section
        else this.$router.push({name: 'Home'})
      }
    }
  }
</script>

<style scoped>

</style>
