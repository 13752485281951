import Vue from 'vue'
import Vuex from 'vuex'
import createCache from 'vuex-cache'
import createPersistedState from 'vuex-persistedstate'






Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    cart: [] // [ { "device": "Portatile 14\"", "band": "Business standard", "quantity": "44", "contractLife": "24 mesi", "vendor": "dell", "accessories": [ "Mouse + Tastiera" ], "activableServices": [ "IperGaranzia Elmec", "Smart Rollout" ] } ]
  },
  mutations: {
    resetCart(state) {
      state.cart = []
    },
    addCart(state, item) {
      state.cart.push(item)
    },
    removeCart(state, index) {
      state.cart.splice(index, 1)
    },
    updateDevice(state, device) {
      state.device = device
    }
  },
  actions: {
    resetCart (context) {
      context.commit('resetCart')
    },
    addCart (context, item) {
      context.commit('addCart', item)
    },
    removeCart (context, index) {
      context.commit('removeCart', index)
    }
  },
  getters: {
    cart: state => state.cart
  },
  plugins: [createPersistedState({
    key: 'mydata'
  }), createCache()]
})
